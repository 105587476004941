import React, { useState } from 'react'
import Layout from '../../components/common/Layout'
import Fade from 'react-reveal/Fade'
import CTA from '../../components/common/CTA'
import FAQ from '../../components/common/FAQ'
import PricingPlans from '../../components/pricing/PricingPlans'
import PageHeader from '../../components/common/PageHeader'
import ContentSection from '../../components/common/ContentSection'
import ElevateSection from '../../components/common/ElevateSection'
import Button from '../../components/common/Button'
import { Space } from '@mantine/core'
import Spotlight from '../../components/home/Spotlight'

const PublicServiceAdministrationPage = () => {
  return (
    <Layout
      meta={{
        description:
          'Empower your team with the tools to make informed decisions and drive public service initiatives forward with collaborative GIS',
        title: 'Atlas for Public Service & Administration',
        type: 'website'
      }}
      title={'Atlas for Public Service & Administration'}
    >
      <main>
        <Fade up duration={1000} delay={0} distance="30px">
          <PageHeader
            title={'Public Service & Administration'}
            description={
              'Empower your team with the tools to make informed decisions and drive public service initiatives forward with collaborative GIS'
            }
            video="https://cdn.atlas.co/landing-pages/public-hero.mp4"
          />
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection centered>
            <h2>
              Improve quality of life for citizens at national, state & local
              level
            </h2>
            <p>
              Being able to rapidly visualize and analyze projects can help
              urban planners to be more proactive and less reactive in the
              planning process.
            </p>
          </ContentSection>
        </Fade>
        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/share-and-discuss.mp4'}
            icon={{ src: '/icons/user-group.svg', color: '#FFF4E7' }}
          >
            <h3>Community engagement</h3>
            <p>
              Engage with your community by sharing interactive maps that allow
              a better understanding of the projects and initiatives you are
              working on.
            </p>
            <p>
              Allow citizens to provide feedback and suggestions on the projects
              to get a better understanding of the community's needs.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/3d-visualization.mp4'}
            reverse
            icon={{ src: '/icons/blueprint.svg', color: '#C2E5FF' }}
          >
            <h3>Urban planning</h3>
            <p>
              Rapidly visualizing and analyzing projects enables urban planners
              to be proactive rather than reactive in the planning process.
            </p>
            <p>
              Atlas facilitates easy collaboration among internal agencies,
              consultancies, and public stakeholders, enhancing planning
              productivity and community engagement.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ContentSection
            video={'https://cdn.atlas.co/landing-pages/ev-chargers.mp4'}
            icon={{
              src: '/icons/database.svg',
              color: '#CDE4E1'
            }}
          >
            <h3>Open Data initiatives</h3>
            <p>
              Innovative cities are investing heavily in accelerating their Open
              Data initiatives, making more datasets accessible to a broader
              audience and ecosystem.
            </p>
            <p>
              By enhancing collaboration and empowering decision-making, your
              city can address its most pressing social and environmental
              challenges using spatial data.
            </p>
          </ContentSection>
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <ElevateSection
            title={'Designed to be easily updated'}
            subtitle={
              'Web maps are live assets designed to be edited, and any changes will be automatically updated synced across all members.'
            }
          />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <Spotlight />
        </Fade>

        <Fade up duration={1000} delay={0} distance="30px">
          <CTA text="Ready to level up your map-making process?" />
        </Fade>
      </main>
    </Layout>
  )
}

export default PublicServiceAdministrationPage
